import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { AbstractModal } from '../abstract-modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../model/user.interface';
import { DeleteUserModalData } from '../../../model/modal.interface';
import { InterviewService } from 'src/app/services/interview.service';
import { GroupInterviewService } from 'src/app/services/group-interview.service';
import { combineLatest, forkJoin, Observable, Subject, of } from 'rxjs';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { IGroupInterview, IInterview } from 'src/app/model/interview.interface';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss']
})
export class DeleteUserModalComponent extends AbstractModal implements OnInit, OnDestroy {

  responsiblePersonForm: FormGroup = this.fb.group({
    responsiblePerson: [null, [Validators.required]],
    videoInterviewsAndNewEmployees: this.fb.group({}),
    groupVideoInterviewsAndNewEmployees: this.fb.group({})
  });

  usersIndividualInterviews: IInterview[];
  usersGroupInterviews: IGroupInterview[];
  usersInterviews$: Observable<[IInterview[], IGroupInterview[]]>;
  individualInterviewsInThePast: IInterview[];
  groupInterviewsInThePast: IGroupInterview[];

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    modalService: ModalService,
    private fb: FormBuilder,
    private interviewService: InterviewService,
    private groupInterviewService: GroupInterviewService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private userService: UserService,
    private setupService: SetupService
  ) {
    super(modalService);
  }

  get data(): DeleteUserModalData {
    return this.modal.data as DeleteUserModalData;
  }

  get responsiblePersonList(): User[] {
    return (this.modal.data as DeleteUserModalData).responsiblePersonList;
  }

  get userId(): number {
    return (this.modal.data as DeleteUserModalData).userId;
  }

  get responsiblePerson(): FormControl {
    return this.responsiblePersonForm.get('responsiblePerson') as FormControl;
  }

  get groupVideoInterviewsAndNewEmployees(): FormGroup {
    return this.responsiblePersonForm.get('groupVideoInterviewsAndNewEmployees') as FormGroup;
  }

  get videoInterviewsAndNewEmployees(): FormGroup {
    return this.responsiblePersonForm.get('videoInterviewsAndNewEmployees') as FormGroup;
  }

  getFormControl(formGroup: FormGroup, interviewGuid: string): FormControl {
    return formGroup.get(interviewGuid) as FormControl;
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.getInterviews();
    this.getInterviewsInThePast();
  }

  getInterviews(): void {
    this.usersInterviews$ = forkJoin([
      this.interviewService.getVideoInterviewsForOtherCustomer(this.userId),
      this.groupInterviewService.getGroupVideoInterviewsForOtherCustomer(this.userId)
    ])
      .pipe(
        switchMap(([individualInterviews, groupInterviews]) => {
          return forkJoin([
            this.interviewService.getAllCustomersPerTimeslot(individualInterviews),
            this.groupInterviewService.getAllCustomersPerTimeslot(groupInterviews)
          ])
            .pipe(
              tap(([individualInterviewsWithCustomers, groupInterviewsWithCustomers]) => {
                this.setInterviewControls(individualInterviewsWithCustomers, groupInterviewsWithCustomers);
                this.usersGroupInterviews = groupInterviews;
                this.usersIndividualInterviews = individualInterviews;
                this.loaderService.hide();
              })
            );
        })
      );
  }

  getInterviewsInThePast(): void {
    combineLatest([
      this.interviewService.interviewsInThePast,
      this.groupInterviewService.interviewsInThePast
    ])
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        tap(([individualInterviewsInThePast, groupInterviewsInThePast]) => {
          this.individualInterviewsInThePast = individualInterviewsInThePast;
          this.groupInterviewsInThePast = groupInterviewsInThePast;
        }),
        switchMap(([individualInterviewsInThePast, groupInterviewsInThePast]) => {
          if (!individualInterviewsInThePast.length && !groupInterviewsInThePast.length) {
            return of(null);
          }
          return this.userService.getAnonymousUser(this.setupService.companyGuid);
        })
      )
      .subscribe((anonymousUser) => {
        if (anonymousUser) {
          this.setInterviewControls(this.individualInterviewsInThePast, this.groupInterviewsInThePast, anonymousUser.guid);
        }
        this.loaderService.hide();
      });
  }

  setInterviewControls(individualInterviews: IInterview[], groupInterviews: IGroupInterview[], userGuid?: string): void {
    individualInterviews
      .forEach((individualInterview) => this.videoInterviewsAndNewEmployees
        .addControl(individualInterview.guid, this.fb.control(userGuid || null, [Validators.required])));

    groupInterviews
      .forEach((groupInterview) => this.groupVideoInterviewsAndNewEmployees
        .addControl(groupInterview.guid, this.fb.control(userGuid || null, [Validators.required])));
  }

  confirm(): void {
    this.responsiblePersonForm.updateValueAndValidity();
    this.responsiblePersonForm.markAllAsTouched();
    if (this.responsiblePersonForm.invalid) {
      this.toastr.error(
        this.translateService.instant('JOBS.FILL_REQUIRED_FIELDS_ERR_MSG')
      );
      return;
    }

    this.data.confirm(this.responsiblePersonForm.value, this.modal);
  }

  close(): void {
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.interviewService.resetInterviewsInThePast();
    this.groupInterviewService.resetInterviewsInThePast();
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
